<template>
  <div class="church-details template-2 convention-promoedit-wrapper">
    <div class="page-body container mx-lg">
      <div class="row">
        <form class="convention-promoedit">
          <div class="details section-top">
            <h2 class="main-title">
              {{ translations.tcAddCampSecurity }}
            </h2>
            <b-form>
              <b-form-group class="form-element">
                <div class="font-style-2">{{ translations.tcMemberName }}</div>
                <div class="g-row">
                  <div class="g-col-1 d-flex">
                    <b-form-input :title="translations.tcName" v-model="searchedPartner.name" id="event-name"
                      :placeholder="translations.tcName" required
                      class="form-control col-left-input g-select flex-0 mr-3">
                    </b-form-input>
                    <b-button :name="`partnerFindButton`" variant="primary" class="flex-0 mr-0 btn-pl-xsm"
                      @click="showModal($event, 'personSearchModal')">
                      {{ translations.tcFind }}
                    </b-button>
                  </div>
                  <div class="g-col-2"></div>
                </div>
              </b-form-group>
            </b-form>
            <div class="form-group">
              <h5 class="custom-form-label">{{ translations.tcRoleName }}</h5>
              <b-form-checkbox-group name="check-button" v-model="securityStateRole" :options="options" switches>
              </b-form-checkbox-group>
            </div>
            <div class="divButton">
              <b-button variant="primary" class="btn-primary" size="sm" @click="addNewMember()">
                {{ translations.tcSave }}
              </b-button>
              &nbsp;
              <b-button variant="light" size="sm" @click="cancel()">
                {{ translations.tcCancel }}
              </b-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <PersonSearch @selectionMade="applyFoundPerson($event)" :camp_country="userCountry.key"></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { Loader } from '@/mixins/Loader'
import PersonSearch from '@/components/PersonSearch.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-member-to-security',
  mixins: [Loader, translationMixin],
  data() {
    return {
      translations: {},
      searchedPartner: {
        name: '',
        indkey: '',
      },
      selectedPartnerKey: '',
      securityStateRole: [],
      options: [
        { text: 'Content Contributor', value: 'EB55F1E8-D0AD-4ACD-AD6E-CB969CEAA4BA' },
        { text: 'Site Administrator', value: 'D05623B2-5FBC-41F7-A477-3769B21987C7' },
      ],
      convention: {},
    }
  },
  methods: {
    ...mapActions({
      getMemberList: 'officersModule/getMemberList',
      saveOrUpdateSecurityManagers: 'officersModule/saveOrUpdateSecurityManagers',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async applyFoundPerson(evt) {
      this.searchedPartner.name = evt.name_text
      this.searchedPartner.indkey = evt.ind_key
      this.selectedPartnerKey = evt.ind_key
    },
    async addNewMember() {
      if (this.securityStateRole.length === 0 || !this.searchedPartner.name) {
        this.$swal({
          text: 'Please enter a member and select at least one role.',
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
        })
      } else {
        let obj = []
        let _obj = {
          rxi_delete_flag: false,
          rxi_ind_key: this.searchedPartner.indkey,
          rxi_key: null,
          rxi_nag_key: null,
          roleType: this.securityStateRole,
        }
        obj.push(_obj)

        this.setLoadingStatus(true)
        let response = await this.saveOrUpdateSecurityManagers(obj)
        if (response === true) {
          this.setLoadingStatus(false)
          this.$swal({
            text: this.translations.tcOperationSuccessful,
            icon: 'success',
            confirmButtonText: this.translations.tcOk,
          }).then(() => {
            this.$router.push({ name: 'officers-security-manager' })
          })
        } else {
          this.$swal({
            text: this.translations.tcOperationFailed,
            icon: 'error',
            confirmButtonText: this.translations.tcOk,
          })
        }
        this.setLoadingStatus(false)
      }
    },
    cancel() {
      this.selectedItem = []
      this.$router.push({ name: 'officers-security-manager' })
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([this.getViewTranslations()]).then((results) => {
        this.options[0].text = this.translations.tcContentContributor
        this.options[1].text = this.translations.tcSiteAdministrator
      })
    } catch (e) {
      console.error('Error in AddMemberToSecurity, created(), ', e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    PersonSearch: PersonSearch,
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      userCountry: 'user/userCountryObject',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ConventionPromoEdit.scss';

div.row {
  margin-top: 10px;
}

#example-datepicker__outer_ .btn {
  color: #212529;
}

.primary {
  margin-bottom: 35px;

  >.col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

h2 {
  font-family: $DIN;
  text-transform: uppercase;
}

h5 {
  text-transform: uppercase;
}

.photoOptions,
.docOptions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  >div {
    margin-right: 10px;

    &:hover {
      opacity: 0.6;
    }

    svg {
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.convention-promoedit {
  width: 100%;
}

.vdp-datepicker div input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
  color: green;
}
</style>
